import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import bg from "../images/staticsvgback.svg"
import { FaRegSadCry } from "react-icons/fa"
import { Trans, useTranslation } from "react-i18next"
const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={`404: ${t("Not found")}`} />
      <div
        className="py-5 bg-light gap-5 d-flex flex-column
    justify-content-center align-items-center"
        style={{
          background: `url(${bg}) no-repeat fixed center`,
          backgroundSize: "contain",
          minHeight: "75vh",
        }}
      >
        <h1 className="display-1">404</h1>
        {/* <div style={{ fontSize: "4rem" }}>😢</div> */}
        <FaRegSadCry
          fontSize="8rem"
          style={{ borderRadius: "4rem" }}
          className="shadow-lg bg-warning text-dark "
        />
        <h2 className="display-6">
          <Trans>Page Not Found</Trans>
        </h2>
        {/* <p>This page doesn't exist</p> */}
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
